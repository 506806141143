.factor-head {
    padding: 16px;
    justify-content: space-between;
    display: flex;
    text-align: center;
}
.layout{

    max-width: 1280px;
    width: 100%;
    margin: auto;
}
.factor-logo{
    width: 113px;
    height: 30px;
}
.page {
    background: white;
    page-break-after: always;
}
.header-table {
    table-layout: fixed;
    border-spacing: 0;
}
.header-table td {
    padding: 0;
    vertical-align: top;
}
.header-item-wrapper {
    border: 1px solid #000;
    width: 100%;
    height: 100%;
    background: #eee;
    display: flex;
    align-content: center;
}
.portait {
    transform: rotate(-90deg) translate(0, 40%);
    text-align: center;
}
.grow {
    width: 100%;
    height: 100%;
}
 .bordered {
     border: 1px solid #000;
     padding: 0.12cm;
 }
 .header-item-data {
     height: 100%;
     width: 100%;
 }
.header-table table {
    width: 100%;
    vertical-align: middle;
}
.label {
    font-weight: bold;
    padding: 0 0 0 2px;
}
.flex-grow {
    flex-grow: 10000000;
}
.header-item-wrapper {
    border: 1px solid #000;
    width: 100%;
    height: 100%;
    background: #eee;
    display: flex;
    align-content: center;
}
.bordered {
    border: 1px solid #000;
    padding: 0.12cm;
}
.content-table {
    border-collapse: collapse;

}
.factor-table{
    border: 1px solid black;
}

/*.content-table  table, th, td {*/
/*    border: 1px solid black;*/
/*    border-collapse: collapse;*/
/*}*/